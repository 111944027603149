import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import ClientForm from "../components/clientForm"

const ClientFormPage = props => (
  <Layout type={1}>
    <Menu type={2} btype={3} />
    <ClientForm data={props} />
    <Footer type={2} />
  </Layout>
)

export default ClientFormPage
