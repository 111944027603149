import React, { useState } from "react"
import styles from "./formC.module.css"
import { Alert } from "@material-ui/lab"

const FormC = ({ setPage, onChg, state, onSub, submitStatus }) => {
  // const [submitStatus, setSubmitStatus] = useState("")
  return (
    <div id="formC" className={styles.form}>
      <div>
        <label className={styles.labels}>
          Let us know where we can send your options to
        </label>
        <input
          name="contactInfo"
          onChange={onChg}
          placeholder="Email"
          type="email"
          className={styles.textfields}
          value={state["contactInfo"]}
        />
        <label className={styles.labels}>Your Name:</label>
        <input
          name="name"
          onChange={onChg}
          placeholder="Name"
          type="text"
          className={styles.textfields}
          value={state["name"]}
        />
        {/* <label>Or</label>
        <input
          placeholder="Phone Number"
          type="tel"
          className={styles.emails}
        /> */}
      </div>
      {/* <div>
        <label>What is your SIN?</label>
        <input
          name="SIN"
          onChange={onChg}
          placeholder="SIN - this will be encrypted"
          type="password"
          className={styles.passwords}
          value={state["SIN"]}
        />
      </div> */}
      <br></br>
      <br></br>
      <div className={styles.box}>
        <div
          onClick={() => setPage(2)}
          className={styles.Bbutton}
          role="button"
        >
          <div className={styles.button_text}>Back</div>
        </div>
        <div className={styles.Nbutton} role="button" onClick={onSub}>
          <div className={styles.button_text}>Submit</div>
        </div>
      </div>
      <div>
        {submitStatus === "success" ? (
          <Alert severity="success">
            <strong>Success: </strong>
            An email will be sent with your ideal recommender. You can submit
            another form by returning home.
          </Alert>
        ) : submitStatus === "fail" ? (
          <Alert severity="error">
            <strong>Error: </strong>
            Something went wrong, please try again.
          </Alert>
        ) : null}
      </div>
    </div>
  )
}

export default FormC
