import React, { useState } from "react"
import { Link } from "gatsby"
import styles from "./formA.module.css"
import { navigate } from "gatsby-link"

const FormA = ({ setPage, onChg, state, data }) => {
  const backToCSite = () => {
    const cSite = "/contractorSite/" + data.location.state.info.id
    navigate(cSite)
  }

  return (
    <div id="formA">
      <div>
        <label className={styles.labels}>
          Are you looking for financing individually or with someone else?
        </label>
        <select
          name="clientStatus"
          id="finsimple-num-of-clients"
          onChange={onChg}
          value={state["clientStatus"]}
        >
          <option value="individually">Individually</option>
          <option value="with someone else">With someone else</option>
        </select>
      </div>
      <div>
        <label className={styles.labels}>
          How much would you like to borrow?
        </label>
        <input
          name="borrowingAmount"
          onChange={onChg}
          placeholder="Amount in CAD"
          type="number"
          className={styles.textfields}
          value={state["borrowingAmount"]}
        />
      </div>
      <div>
        <label className={styles.labels}>
          When would you like to start your project?
        </label>
        <select
          name="projectStartDate"
          id="finsimple-project-start-date"
          onChange={onChg}
          value={state["projectStartDate"]}
        >
          <option value="as soon as possible">As Soon As Possible</option>
          <option value="within two weeks">Within 2 Weeks</option>
          <option value="within one month">Within 1 Month</option>
          <option value="one month and beyond">1 Month and Beyond</option>
        </select>
      </div>
      <div>
        <label className={styles.labels}>
          What's your estimated credit score?
        </label>
        <select
          name="estimatedCreditScore"
          id="finsimple-estimated-credit-score"
          onChange={onChg}
          value={state["estimatedCreditScore"]}
        >
          <option value="excellent(850-741)">Excellent (850-741)</option>
          <option value="great(740-721)">Great (740-721)</option>
          <option value="veryGood(720-701)">Very Good (720-701)</option>
          <option value="good(700-681)">Good (700-681)</option>
          <option value="average(680-661)">Average (680-661)</option>
          <option value="fair(660-641)">Fair (660-641)</option>
          <option value="poor(640-621)">Poor (640-621)</option>
          <option value="veryPoor(620-350)">Very Poor (620-350)</option>
        </select>
      </div>
      <div className={styles.box}>
        <a onClick={() => backToCSite()}>
          <div className={styles.Bbutton} role="button">
            <div className={styles.button_text}>Cancel</div>
          </div>
        </a>
        <div
          onClick={() => setPage(2)}
          className={styles.Nbutton}
          role="button"
        >
          <div className={styles.button_text}>Next</div>
        </div>
      </div>
    </div>
  )
}

export default FormA
