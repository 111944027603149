import React, { useState, useCallback } from "react"
import styles from "./clientForm.module.css"
import FormC from "./formSubPages/formC"
import FormB from "./formSubPages/formB"
import FormA from "./formSubPages/formA"

import { API, graphqlOperation, Auth } from "aws-amplify"
import { createClient } from "../graphql/mutations.js"
import { navigate } from "gatsby-link"

const ClientForm = ({ data }) => {
  const [page, setPage] = useState(1)
  const [submitStatus, setSubmitStatus] = useState("")
  const [client, setClient] = useState({
    clientStatus: "",
    borrowingAmount: undefined,
    projectStartDate: "",
    estimatedCreditScore: "",
    currentEmploymentStatus: "",
    annualIncome: undefined,
    monthlyHousingPayment: undefined,
    contactInfo: undefined,
    SIN: undefined,
    name: "",
  })

  const pageHandler = useCallback(
    pageNum => {
      console.log(pageNum)
      setPage(pageNum)
    },
    [setPage]
  )

  const handleInputChange = useCallback(
    e => {
      const { currentTarget } = e
      const { value, name, type } = currentTarget

      setClient({
        ...client,
        [name]: value,
      })
    },
    [setClient, client]
  )

  const onSubmit = useCallback(
    async e => {
      try {
        e.preventDefault()

        console.log(data.location.state.info.id)
        const cSite = "/contractorSite/" + data.location.state.info.id
        console.log(cSite)
        await Auth.signIn("apkanwar@gmail.com", "password")

        await API.graphql(
          graphqlOperation(createClient, {
            input: {
              annualIncome: client.annualIncome,
              borrowingAmount: client.borrowingAmount,
              clientStatus: client.clientStatus,
              contractorID: data.location.state.info.id,
              currentEmploymentStatus: client.currentEmploymentStatus,
              email: client.contactInfo,
              estimatedCreditScore: client.estimatedCreditScore,
              monthlyHousingPayment: client.monthlyHousingPayment,
              name: client.name || "TEST",
              projectStartDate: client.projectStartDate,
            },
          })
        )

        await sendRecommendation()

        setClient({
          clientStatus: "",
          borrowingAmount: undefined,
          projectStartDate: "",
          estimatedCreditScore: "",
          currentEmploymentStatus: "",
          annualIncome: undefined,
          monthlyHousingPayment: undefined,
          contactInfo: "",
          SIN: undefined,
          name: "",
        })
        // navigate(cSite)
        setSubmitStatus("success")

        await Auth.signOut()
      } catch (e) {
        console.log(`Client form submit failed: ${e}`)
        setSubmitStatus("fail")
      }
    },
    [setClient, client]
  )

  const sendRecommendation = useCallback(async () => {
    console.log("click")
    const res = await fetch("/.netlify/functions/clientRecommendation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      // TODO: need to change hardcode values once we get more lenders - also need to refactor
      // logic so that determining ideal lender is done in lambda itself.
      body: JSON.stringify({
        name: client.name,
        clientEmail: client.contactInfo,
        lender_name: "Sofi",
        lender_referral_link:
          "https://www.sofi.com/?__cf_chl_jschl_tk__=a3429ba49f2633cc856ffd8b9fd37e5b6c1922a8-1621191356-0-ASDH5HQDfGoYh2CcVqW2Z_9wMyjM1WxH57gZAgOC8dwiSQYZlvfyAsTUoXyvo0y5shBS1GcfGWtvggDYenbmnvfJFeg7XZcB0UCp3UzzWCsXRSwL_6qi3yXhZyrUrDYjygZErQfUC1Ofubv8MU2_t9XGgSuqbCIyEv3rzenux8N6jWY_ikkN0ffawXlG77IYSOzBR8-LmeB_1TEXkxWDVaJb14WOVoBUQGCxMAPOK5fseXeMlX-_8t5t1URPj-mBXZtcdTbaOrZ2uqjtmuOG5tk6YervQlU0Z0s1xlc0roq9bvrjfQILO0siXcfx4EmcM34Rgr1nqk5-8qo1VN0q9EjDQbjtObgXyCpVeGSbvpgmC2wndStmDeF7bYFdAR6O8eW7avcdLnLV-XLK--d_sm-tTUe_hO-wxC5Td8CdMNbnjL6DH4CMduKCCsiMZa6ipESd28dHsdJ7iKz1j5yohdg",
      }),
    })
    console.log(res)
    if (res.status !== 200) throw Error("Email Failed: " + res.body)
  }, [client])

  return (
    <div className={styles.container} id="clientForm">
      <div className={styles.loginCard}>
        <div className={styles.login}>
          <div className={styles.header}>
            <span className={styles.highlight}>Client</span> Form
          </div>
          <form>
            {page === 1 ? (
              <FormA
                setPage={pageHandler}
                onChg={handleInputChange}
                state={client}
                data={data}
              />
            ) : page === 2 ? (
              <FormB
                setPage={pageHandler}
                onChg={handleInputChange}
                state={client}
              />
            ) : (
              <FormC
                setPage={pageHandler}
                onChg={handleInputChange}
                state={client}
                onSub={onSubmit}
                submitStatus={submitStatus}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default ClientForm
