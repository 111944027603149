import React, { useState } from "react"
import styles from "./formB.module.css"

const FormB = ({ setPage, onChg, state }) => {
  const [submitStatus, setSubmitStatus] = useState("")
  return (
    <div id="formB">
      <div>
        <label className={styles.labels}>
          What's your current employment status?
        </label>
        <select
          name="currentEmploymentStatus"
          id="finsimple-current-emp-status"
          onChange={onChg}
          value={state["currentEmploymentStatus"]}
        >
          <option value="employed">Employed</option>
          <option value="self employed">Self Employed</option>
          <option value="retired">Retired</option>
          <option value="not employed">Not Employed</option>
        </select>
      </div>
      <div>
        <label className={styles.labels}>What is your annual income?</label>
        <input
          name="annualIncome"
          onChange={onChg}
          placeholder="Income in CAD"
          type="number"
          className={styles.textfields}
          value={state["annualIncome"]}
        />
      </div>
      <div>
        <label className={styles.labels}>
          How much is your monthly housing payment?
        </label>
        <input
          name="monthlyHousingPayment"
          onChange={onChg}
          placeholder="Rent or Morgatge payment in CAD"
          type="number"
          className={styles.textfields}
          value={state["monthlyHousingPayment"]}
        />
      </div>

      <div className={styles.box}>
        <div
          onClick={() => setPage(3)}
          className={styles.Nbutton}
          role="button"
        >
          <div className={styles.button_text}>Next</div>
        </div>
        <div
          onClick={() => setPage(1)}
          className={styles.Bbutton}
          role="button"
        >
          <div className={styles.button_text}>Back</div>
        </div>
      </div>
    </div>
  )
}

export default FormB
