/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContractor = /* GraphQL */ `
  mutation CreateContractor(
    $input: CreateContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    createContractor(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      logoFileName
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        items {
          id
          name
          email
          phoneNumber
          clientStatus
          borrowingAmount
          projectStartDate
          estimatedCreditScore
          currentEmploymentStatus
          annualIncome
          monthlyHousingPayment
          contractorID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateContractor = /* GraphQL */ `
  mutation UpdateContractor(
    $input: UpdateContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    updateContractor(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      logoFileName
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        items {
          id
          name
          email
          phoneNumber
          clientStatus
          borrowingAmount
          projectStartDate
          estimatedCreditScore
          currentEmploymentStatus
          annualIncome
          monthlyHousingPayment
          contractorID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteContractor = /* GraphQL */ `
  mutation DeleteContractor(
    $input: DeleteContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    deleteContractor(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      logoFileName
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        items {
          id
          name
          email
          phoneNumber
          clientStatus
          borrowingAmount
          projectStartDate
          estimatedCreditScore
          currentEmploymentStatus
          annualIncome
          monthlyHousingPayment
          contractorID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      clientStatus
      borrowingAmount
      projectStartDate
      estimatedCreditScore
      currentEmploymentStatus
      annualIncome
      monthlyHousingPayment
      contractorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contractor {
        id
        name
        email
        phoneNumber
        logoFileName
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        clients {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      clientStatus
      borrowingAmount
      projectStartDate
      estimatedCreditScore
      currentEmploymentStatus
      annualIncome
      monthlyHousingPayment
      contractorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contractor {
        id
        name
        email
        phoneNumber
        logoFileName
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        clients {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      email
      phoneNumber
      clientStatus
      borrowingAmount
      projectStartDate
      estimatedCreditScore
      currentEmploymentStatus
      annualIncome
      monthlyHousingPayment
      contractorID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contractor {
        id
        name
        email
        phoneNumber
        logoFileName
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        clients {
          nextToken
          startedAt
        }
      }
      owner
    }
  }
`;
